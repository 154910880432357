.editor-color-holder {
  height: 30px;
  width: 30px;
}

.vertical-separator {
  background: rgba(57, 76, 96, 0.15);
  width: 1px;
  height: 30px;
  margin: 0 12px;
}

.editor-toolbox {
  height: 54px;
  background: #ffffff;
  border-bottom: 1px solid rgba(57, 76, 96, 0.15);
  padding: 0 0.8rem;
}
