.navbar {
  /*background: linear-gradient(90deg, #00c4cc, #7d2ae8);*/
  background: #fff;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.36rem;
  border-bottom: 1px solid #bcbcbc;
}

.navbar-left {
  //color: #fff;
  display: flex;
  svg {
    //width: 200px;
    width: 55px;
    height: 50px;
    display: inline-flex;
  }
}

.navbar-right {
  display: flex;
  justify-content: center;
}

.navbar-action-items {
  color: #fff;
  display: flex;
}

.navbar-icon {
  border-radius: 4px;
  padding: 0.5rem;
  background: rgba($color: #fff, $alpha: 0.15);
  margin-left: 1rem;
  cursor: pointer;
}

.navbar-main-icon {
  border-radius: 4px;
  padding: 0.5rem;
  background: rgba($color: #fff, $alpha: 0.15);
  margin-left: 1rem;
  cursor: pointer;
}
