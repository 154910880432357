.footermenu {
  height: 50px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center; /* 내부 텍스트를 가운데 정렬합니다. */
}

.footermenu-logo {
  height: 20px;
  float: left;
  padding-right: 30px;
}

.footermenu-text {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  position: absolute; /* footermenu-text를 footermenu의 기준으로 절대 위치시킵니다. */
  left: 50%; /* footermenu의 중앙에 위치시키기 위해 왼쪽으로 50% 이동합니다. */
  transform: translateX(-50%); /* footermenu-text를 가운데로 정렬합니다. */
}

.footermenu-png {
  position: absolute; /* footermenu-png를 footermenu의 기준으로 절대 위치시킵니다. */
  right: 10px; /* 우측으로 20px 정도의 여백을 둡니다. */
  cursor: pointer;
}