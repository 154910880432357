.editor-toolbox.objects {
  height: 54px;
  background: #ffffff;
  border-bottom: 1px solid rgba(57, 76, 96, 0.15);
  display: flex;
  align-items: center;
  position: relative;
  //justify-content: space-between;
  justify-content: left;
  .section-two {
    display: flex;
  }
}